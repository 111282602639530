.weekof {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  
  &:hover .weekselector {
    opacity: 1;
    cursor: pointer;
  }
}

.weekselector {
  opacity: .5;
  height: 24px;
  width: auto;
  transition: opacity 0.5s ease;
}