.unleashedArticle {
  margin: 0 auto;
}

.desktop {
  &.unleashedArticle {
    width: 50%;
  }
}

.mobile {
  &.unleashedArticle {
    width: 90%;
  }
}