.chargetype {
  border: 4px solid black;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  opacity: 1;
  transition: all .5s ease;

  &:hover {
    background-color: lightgray;
  }

  svg {
    height: 64px;
    width: 64px;
  }
}

.addbutton {
  display: flex;
  width: 80%;
  margin: 0 auto;
  gap: 16px;
  justify-content: center;
}

.mobile {
  & .chargetype {
    font-size: 32px;
    font-weight: bold;
  }
}

.desktop {
  & .chargetype {
    font-size: 64px;
  }
}