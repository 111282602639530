.searchMenu, .optionList {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mobile {
  .optionList {
    // flex-direction: column;

    span {
      font-weight: bold;
    }
  }

  gap: 8px;
}

.desktop {
  .optionList {
    //that first style rule
    flex-direction: row;
  }
}