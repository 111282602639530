$padding: 8px;

.choiceSelect, .valueInput, .commit {
  padding: $padding;
  border-radius: $padding;
  transition: background-color ease .5s;
  
  &:hover {
    background-color: lightgray;
  }
}

.choiceSelect, .commit:enabled {
  cursor: pointer;
}

.commit:disabled {
  cursor: not-allowed;
  background-color: inherit;

  &:hover {
    background-color: inherit;
  }
}

.container {
  display: flex;
  gap: 8px;
  justify-content: center;
}

.mobile {
  &.container {
    flex-direction: column;
    
    max-width: 50%;
    min-width: min-content;
    margin: 0 auto;
  }
}