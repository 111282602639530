.center {
  text-align: center;
}

.linkoverride {
  color: inherit;
  text-decoration: inherit;
}

.huge {
  font-size: 32px;
}

.large {
  font-size: 28px;
}

.medium {
  font-size: 24px;
}

.nowrap {
  white-space: nowrap;
}