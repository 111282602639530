.accordion {
  border: 1px solid black;
  border-collapse: collapse;

  border-radius: 6px;
  overflow: hidden;
  
  margin: 8px;
}

.heading {
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.5s ease;
  cursor: pointer;
}

.content {
  margin: 8px;
}

.animationContainer {
  transition: height 0.5s ease;
  overflow: hidden;
}

.arrow {
  transition: transform 0.5s ease;
}

.open {
  .heading {
    background-color: lightgray;
  }

  .arrow {
    transform: rotate(180deg);
  }

  .animationContainer {
    height: var(--height);
  }
}

.closed {
  .animationContainer {
    height: 0;
  }
}
