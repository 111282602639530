.listing {
  width: calc(100% - 32px);
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0 black;
  margin: 16px;
  overflow: hidden;
}

.mobile {
  .listingPhoto {
    width: 100%;
  }

  .articleContent {
    margin: 8px;
  }

  .badges {
    .honor {
      display: inline-block;
      width: fit-content;
    }

    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  //Sometimes, there's no badges at all. flex-gap still makes an annoying gap though
  .badges:empty {
    display: none;
  }
}

.desktop {
  .listingPhoto {
    width: 25%;
  }

  .listing {
    display: flex;
    column-gap: 8px;  
  }
}

.articleType, .honor {
  text-transform: uppercase;
}

.articleType {
  color: #428bca;
}

.honor {
  background-color: #ebebeb;
  padding: 8px;
  border-radius: 4px;
  margin-right: 8px;
}

