$padding: 8px;

.charge {
  border-radius: 8px;
  border: 4px solid black;
  
  &:hover .actionbutton {
  display: block;    
  }
}

.padded {
  padding: 0 8px;
}

.actionbutton {
  display: none;
  position: absolute;
  right: -16px;
  background-color: white;
  border-radius: 16px;
  padding: 8px;
  border: 4px solid black;
  cursor: pointer;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: lightgray;
  }
}

.top {
  top: -16px;
}

.mobile {
  &.charge {
    display: flex;
    flex-direction: column;
    padding: $padding;
    gap: 8px;
  }

  .description {
    font-size: 16px;
  }
}

.desktop {
  &.charge {
    display: grid;
    grid-template-columns: minmax(min-content, 10%) auto;
    position: relative;  
    padding: $padding 0;
  }
}