$maxWidth: 25vw;

.inline-image,
.regular-image {
  padding: 8px;
  text-align: center;
  margin: 8px;
}

.inline-image {
  border: 1px solid black;
  min-width: fit-content;

  img {
    max-width: $maxWidth;
    max-height: 25vh;
    display: block;
    margin: 0 auto;
  }

  p {
    max-width: $maxWidth;
  }
}

.regular-image img {
  max-width: 80%;
  display: block;
  margin: 0 auto;
}
